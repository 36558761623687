<template>
  <div>
    <!--头部-->
    <PageTop1></PageTop1>
    <PageTop ></PageTop>
    <!-- 订单结算金额 -->
    <div class="pay-ment-box">
      <!-- 结算金额 -->
      <div class="total-amount-box" v-if="type === 'bill'">
        <div class="left">
          <img src="./image/icon_submit.png" alt />
          <div>
            <p>账单支付！</p>
            <p>
              <span>账单编号：{{ billInfo.data.documentNo }}</span>
              <span>账单周期：{{ billInfo.data.billCycle }}</span>
            </p>
          </div>
        </div>
        <div class="right">
          <p>总计</p>
          <p>¥</p>
          <p>{{ billInfo.data.accountsPayable }}</p>
        </div>
      </div>

      <div class="total-amount-box" v-else>
        <div class="left">
          <img src="./image/icon_submit.png" alt />
          <div>
            <p>订单提交成功，请尽快支付！</p>
            <p class="cd">
              请在
              <span v-if="countDown.show">
                <countdown :value="countDown.value" format="h时m分s秒" @stop="countDownStopHandler" />
              </span>
              内完成支付，否则订单会被自动取消
            </p>
            <p>
              <span>订单编号：{{ order.data.orderNumber }}</span>
              <span>商品名称：{{ order.data.productName }}</span>
              <span>下单时间：{{ order.data.orderAt }}</span>
            </p>
          </div>
        </div>
        <div class="right" style="display: flex; flex-direction: column;">
          <div style="font-size: 16px; display: flex; align-items: center;">
            <span style="font-size: 14px;">总计：</span>
            <!--非票折3优惠金额-->
            <template v-if="!payment.list.piaozhe3">
              <span :class="{'total-amount': actIndex == 1}">¥</span>
              <span :class="{'total-amount': actIndex == 1}" v-if="!orderAmountInfo.id">{{ payment.list.tatil || 0 }}</span>
              <span :class="{'total-amount': actIndex == 1}" v-else>
                {{ orderAmountInfo.oldRealPayAmount ? orderAmountInfo.oldRealPayAmount.toFixed(2) : '0:00' }}
              </span>
            </template>

            <!--票折3的单子-->
            <template v-else>
              <span>¥</span>
              <span>{{ payment.list.immediatePayment.money }}</span>
            </template>
          </div>

          <!--非票折3优惠金额-->
          <template v-if="actIndex == 0 && orderAmountInfo.id">
            <div style="font-size: 16px; color: #ff3f3f;">
              <span style="font-size: 14px;">立减：</span>
              <span>¥</span>
              <span>{{ orderAmountInfo.onlinePaymentInstantDiscount ? orderAmountInfo.onlinePaymentInstantDiscount.toFixed(2) : '0.00' }}</span>
            </div>
            <div style="font-size: 20px; color: #ff3f3f; font-weight: bold; margin-top: 4px;">
              <span style="font-size: 18px;">实付：</span>
              <span>¥</span>
              <span>{{ orderAmountInfo.realPayAmount ? orderAmountInfo.realPayAmount.toFixed(2) : '0.00' }}</span>
            </div>
          </template>

          <!--票折3的单子 优惠金额-->
          <template v-if="payment.list.piaozhe3">
            <div style="font-size: 20px; font-weight: bold; margin-top: 4px;">
              <span style="font-size: 18px;">待付金额：</span>
              <span>¥</span>
              <span>{{ payment.list.immediatePayment.realMoney }}</span>
            </div>
            <div style="font-size: 16px; color: #ff3f3f;">
              <span style="font-size: 14px;">(优惠8%：</span>
              <span>¥</span>
              <span>{{ (payment.list.immediatePayment.money - payment.list.immediatePayment.realMoney).toFixed(2) }})</span>
            </div>
          </template>
        </div>
      </div>

      <!-- 选择支付方式 -->
      <div class="pay-type-box" v-if="payment && payment.list && (payment.list.immediatePayment || payment.list.offlinePayment )">
          <p class="titleBox">
            <span>支付方式</span>
            <span style="color: #E5432E; font-size: 14px; margin-left: 10px;">*请选择以下两种方式（否则订单会被取消）</span>
          </p>
          <div class="payTabs" v-if="payment && payment.list && payment.list.immediatePayment">
              <div :class="['item', payment.list.immediatePayment.checked ? 'act_item' : '']"
                   @click="tabChange(payment.list.immediatePayment.title,0, payment.list.immediatePayment.money)"
                   v-if="payment.list.immediatePayment.show">
                  <div class="onLineBox">
                      <div class="onLine-le">
                          <span class="onLine-le-title">{{payment.list.immediatePayment.title}}</span>
                      </div>
                      <div :class="['onLine-ri',payment.list.immediatePayment.checked ? '' : 'dis_item']"
                            v-if="payment.list.immediatePayment.addedTitle && payment.list.immediatePayment.addedTitle > '0'"
                      >（最高享{{payment.list.immediatePayment.addedTitle}}‰立减）</div>
                  </div>
              </div>
              <div :class="['item', payment.list.offlinePayment.checked ? 'act_item' : '']"
                   @click="tabChange(payment.list.offlinePayment.title,1, payment.list.offlinePayment.money)"
                   v-if="payment.list.offlinePayment.show">
                  <div class="onLineBox">
                      <div class="onLine-le">
                          <span class="onLine-le-title">{{payment.list.offlinePayment.title}}</span>
                      </div>
                      <div :class="['onLine-ri',payment.list.offlinePayment.checked ? '' : 'dis_item']" v-if="payment.list.offlinePayment.payName == 'COD'">
                        (送货上门、收货付款)
                      </div>
                  </div>
              </div>
          </div>

          <!--支付方式为立即支付-->
          <div class="payTypeBox" v-if="actIndex === 0">
              <div v-if="payment.list.immediatePayment && payment.list.immediatePayment.list && payment.list.immediatePayment.list.length > 0">
                  <div class="payTypeBox-list" v-for="(item,index) in payment.list.immediatePayment.list" :key="index">
                      <div class="title">
                          <span>{{item.title}}</span>
                          <span class="des" v-if="item.onlinePaymentPercentage">(享{{ item.onlinePaymentPercentage }}‰立减 自动下账)</span>
                      </div>
                      <p v-if="item.title == '公对公支付'" class="bk_des">
                          <span class="iconfont icon-prompt-fill sp"></span>
                          请确认已插入企业网银UKEY，以便正常使用企业网银支付。
                      </p>
                      <div class="typeList">
                          <div
                            :class="['payItem', (payment.mode == temp.code && currentTitle == item.title) ? 'act_pay' : '']"
                            v-for="(temp, tindex) in item.list"
                            :key="tindex"
                            @click="choosePayWay(temp, item.title)">
                            <div style="width: 40px; text-align: center; margin-left: 8px;">
                              <span :class="['iconfont', temp.icon]" :style="{'color':temp.color}"></span>
                            </div>

                            <div style="flex: 1">
                              <div>{{ temp.name }}</div>
                              <div style="font-size: 12px; color: #878787;">{{ temp.subheading }}</div>
                              <div
                                v-if="item.type == -1 && temp.onlinePaymentPercentage"
                                style="font-size: 12px;"
                                :style="{'color': temp.type === 'ONLINE' ? '#878787' : 'red'}">
                                享{{ temp.onlinePaymentPercentage }}‰立减
                              </div>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <!--支付方式为货到付款-->
          <div class="payTypeBox" v-else>
            <!--线下付款-->
              <div v-if="payment.list.offlinePayment.payName === 'OFFLINEPAY'">
                <div class="xx-des">专属开票员会主动联系你，注意电话接听~</div>
              </div>

              <!--货到付款  sffdPj(客户票折3 1.票折3)   bak18(次月结  *.月结  其他类型全部是次结)-->
              <div v-else-if="(payment.list.piaozhe3 && payment.list.offlinePayment.payName !== 'COD') || !(payment.list.sffdPj == 1 && (payment.list.bak18 === '*' || !payment.list.bak18))">
                  <div class="tips" :style="{'font-size':payment.list.offlinePayment.list[0].font,'color':payment.list.offlinePayment.list[0].color}">
                      {{payment.list.offlinePayment.list[0].title}}
                  </div>
                  <div class="channels" :style="{'font-size':payment.list.offlinePayment.list[1].font,'color':payment.list.offlinePayment.list[1].color}">
                      {{payment.list.offlinePayment.list[1].title}}
                  </div>
                  <div class="channels-des" :style="{'font-size':payment.list.offlinePayment.list[2].font,'color':payment.list.offlinePayment.list[2].color}">
                      {{payment.list.offlinePayment.list[2].title}}
                  </div>

                  <div style="font-size: 16px; font-weight: bold; margin-top: 30px; text-align: left;">对公转账信息</div>

                  <div style="position: relative;" :style="{height: expandOrNot ? 'auto' : '190px', 'overflow': expandOrNot ? 'auto' : 'hidden'}">
                      <div class="toBankMsg" v-for="(item,index) in payment.list.offlinePayment.list2" :key="index">
                          <div class="toBankMsgBox">
                              <div class="toBankMsg-name" :style="{ 'background': item.recommend == 1 ? '#ff5a5a' : '#999999' }">{{ item.title }}</div>
                              <div class="des">复制22位账号，对公付款 自动下账</div>
                          </div>
                          <div class="toBankMsg-content">
                              <div class="tbm-item">
                                  <div class="label-name">单位名称</div>
                                  <div>{{item.companyName}}</div>
                              </div>
                              <div class="tbm-item">
                                  <div class="label-name">账户</div>
                                  <div class="bankNum">
                                      <span>{{item.account}}</span>
                                      <span v-if="item.recommend == 1">{{userInfo.account}}</span>
                                      <span class="copyBox" @click="copyCode(item, userInfo.account)">复制</span>
                                  </div>
                              </div>
                              <div class="tbm-item">
                                  <div class="label-name">开户行</div>
                                  <div>{{item.bankAddress}}</div>
                              </div>
                          </div>
                      </div>

                      <div class="expand-button" :style="{'position': !expandOrNot ? 'absolute' : 'initial'}" @click="expandOrNot = !expandOrNot">
                          <span>{{ expandOrNot ? '收起' : '展开更多' }}</span>
                          <i class="el-icon-d-arrow-right" :style="{'transform': expandOrNot ? 'rotate(-90deg)' : 'rotate(90deg)'}"></i>
                      </div>
                  </div>
              </div>
          </div>
        <p class="payMsg" v-if="actIndex === 0 && payment.mode == ''">请选择支付方式</p>
        <div class="button-box">
          <template v-if="actIndex === 0">
            <div class="payBtn" v-if="!payment.mode || payment.mode === 'FRIENDTOPAY'">选择支付（银行）</div>
            <div class="payBtn actBtn animated delay1" @click="toPayModal('ONLINE')" v-else>确认订单（立即支付）</div>
          </template>

          <template v-else-if="actIndex === 1">
            <div class="payBtn" v-if="!payment.list.offlinePayment.checked">{{payment.list.offlinePayment.payName == 'COD' ? "确认订单（货到付款）" : "确认订单（线下付款）"}}</div>
            <div class="payBtn actBtn animated delay1" @click="toPayModal('OFFLINE')" v-else>{{payment.list.offlinePayment.payName == 'COD' ? "确认订单（货到付款）" : "确认订单（线下付款）"}}</div>
          </template>
        </div>
      </div>
    </div>
    <!-- 二维码弹框 -->
    <div class="model-box" v-show="qrCodeModal.show">
      <div class="center-box">
        <!-- 关闭按钮 -->
        <div class="box-header">
          <p>{{ qrCodePaymentMethod }}支付</p>
          <img @click="closePayModal" src="./image/icon_close.svg" alt />
        </div>

        <!-- 二维码 -->
        <div class="box-content">
          <div class="box-pay">
            <div class="qrcode-countdown">
              <span v-if="countDown.value">
                距离二维码过期还剩
                  <countdown style="color: rgba(244, 87, 87, 1);"
                          :value="countDown.value"
                          format="h时m分s秒"
                          @stop="countDownStopHandler"
                  />，过期后请重新获取二维码。
              </span>
            </div>
            <div class="code-box">
              <!-- 原来需要调接口然后拼接 -->
              <img alt="二维码" :src="qrCodeModal.qrCodeUrl" />
<!--              <span-->
<!--                class="reget"-->
<!--                v-if="qrCodeModal.regetShow"-->
<!--                @click="regetQrCode"-->
<!--              >-->
<!--                <i>点击重新获取二维码</i>-->
<!--              </span>-->
            </div>
            <p>请使用{{ qrCodePaymentMethod }}扫一扫 扫码支付</p>
          </div>
          <div class="title-box">
            <p>
              交易金额
              <span v-if="type === 'bill'">{{ billInfo.data.accountsPayable }}</span>
              <!--票折3订单金额-->
              <span v-else-if="payment.list.piaozhe3">{{ payment.list.immediatePayment.realMoney }}</span>
              <!--非票折3订单金额-->
              <template v-else>
                <span v-if="actIndex == 0 && orderAmountInfo.id">{{ orderAmountInfo.realPayAmount ? orderAmountInfo.realPayAmount.toFixed(2) : '0.00' }}</span>
                <span v-else>{{ orderAmountInfo.oldRealPayAmount ? orderAmountInfo.oldRealPayAmount.toFixed(2) : '0.00' }}</span>
              </template>
              元
            </p>
          </div>
        </div>
      </div>
    </div>
      <!-- 欠款提示 -->
    <Dialog :show="showDebt" :title="arrearsData.debitFlag" width="400px" height="300px" @close="showDebt = false">
      <div class="arrears-dialog" style="border-radius: 10px">
          <div class="proBox1">
              <div class="proBox-msg" v-if="arrearsData.currentSumDebit > 0">
                  <p>截止当前，<span style="color: #E5432E">您已累计欠款{{ arrearsData.currentSumDebit }}元，</span></p>
                  <p>为不影响您正常下单，请立即还款。</p>
              </div>
              <div class="proBox-msg" v-if="arrearsData.currentSumDebit == 0">
                  <p>请联系业务员调整授信额度。</p>
              </div>
              <div class="proBox-btn" v-if="arrearsData.currentSumDebit > 0" @click="nowArrears">
                  <button type="button" @click="nowArrears">立即还款</button>
              </div>
          </div>
      </div>
    </Dialog>
    <PageBottom></PageBottom>
  </div>
</template>
<script>
import Dialog from '@/components-v2/v2-dialog/index'
import debounce from "lodash/debounce";
import PageTop from "@/components-v2/layout/page-top"
import PageTop1 from "@/components-v2/layout/page-top1"
import PageBottom from "@/components-v2/layout/page-bottom"
import { Countdown } from "@/components-v2/countdown";
import publicMethods from '@mixin/publicMethods'
import { AccountStorage } from "@services/account";

const accountStorage = new AccountStorage();

export default {
  components: {
    Dialog,
    PageBottom,
    Countdown,
    PageTop,
    PageTop1
  },
  data() {
    return {
      id: "",
      type: "order",
      orderDetail: {},
      serialNumber: "",
      createQrCodeUrlPrefix: "/pjyy-deepexi-aggregation/api/v1/qrcode/getQrCode",
      billInfo: {
        data: {}
      },
      order: {
        data: {}
      },
      payment: {
        list: {},
        mode: ""
      },
      countDown: {
        show: false,
        value: 0
      },
      qrCodeModal: {
        show: false,
        overtime: 60,
        regetShow: false,
        qrCodeUrl: ""
      },
      payUrl:"",
      act_str:"",
      act_money:"",
      actIndex:0,
      userInfo:{},
      showDebt: false,//欠款弹窗
      arrearsData: {},//欠款信息
      currentTitle:"",
      expandOrNot: false,  // 是否展开
      paymentData: {},  // 保存支付的数据
      orderAmountInfo: {},  // 订单金额信息
      qrCodePaymentMethod: '',   // 二维码支付方式标题
    };
  },
  mixins: [publicMethods],
  async created() {
    const params = this.$route.query;
    const id = params.orderId;
    const type = params.type;
    this.id = id;
    type && (this.type = type);
    this.userInfo = accountStorage.getCacheUserInfo();
    await this.initPayMode();
    if (type === "bill") {
      await this.initBillDetailData();
    } else {
      await this.initOrderDetailData();
    }
  },
  beforeDestroy() {
    this.stopCheckOrderPayStatus();
    this.clearRegetQrCodeTimeout();
  },
  methods: {
      //复制卡号
      copyCode(item, val1) {
        // 创建输入框元素
        let oInput = document.createElement('input');
        // 将想要复制的值
        oInput.value = item.account;
        if (item.recommend == 1) {
          oInput.value += val1
        }
        // 页面底部追加输入框
        document.body.appendChild(oInput);
        // 选中输入框
        oInput.select();
        // 执行浏览器复制命令
        document.execCommand('Copy');
        // 弹出复制成功信息
        this.$messageTip("账户复制成功！", 'success');
        // 复制后移除输入框
        oInput.remove();
      },
      //模式tab切换
      tabChange(str,index){
          this.actIndex = index;
          if (this.actIndex === 0){
              this.payment.list.immediatePayment.checked = true
              this.payment.list.offlinePayment.checked = false
          }else{
              this.payment.list.immediatePayment.checked = false
              this.payment.list.offlinePayment.checked = true
          }
          this.$forceUpdate()
      },
      //支付渠道选择
      async choosePayWay(item, title) {
        this.currentTitle = title;
        this.payment.mode = item.code;
        this.paymentData = {
          id: item.id,
          code: item.code,
          type: item.type,
          promotionId: item.promotionId,  // 文案号
          name: item.name,
        }

        if (item.name === '找朋友付') {
          this.findAFriendToPay(this.order.data.orderNumber, this.payment.list.tatil, true)
        } else {
          this.payment.list.immediatePayment.checked = true
          this.payment.list.offlinePayment.checked = false

          // piaozhe3 为 false 时，不查询金额（不是票折3的单子）
          if (this.type === 'order' && !this.payment.list.piaozhe3) {
            this.getOrderAmountInfo()
          }
        }
      },
      // 查询金额
      async getOrderAmountInfo() {
        let params = this.paymentData;
        params.payType = 'ONLINE';
        params.orderNumber = this.id;
        let api = '/pjyy-deepexi-order-center/rpc/v3/order/OcOrder/getOrderAmount';
        let res = await this.$getHttpClient().post(api, params)
        let { code, data } = res.data;
        if (code == 200) {
          this.orderAmountInfo = data || {};
        }
      },
      //获取活动提示
      getPayActTip() {
          let tip = "";
          if (this.payment.list && this.payment.list.immediatePayment && this.payment.list.immediatePayment.addedTitle) {
              let percent = this.payment.list.immediatePayment.addedTitle;
              tip = `活动期间，线上支付立减${percent}‰`;
          }
          if (this.order.whetherCashCustomer) {
              if (tip.length > 0) {
                  tip += ",";
              }
              //现金客户
              tip += "*线上支付：完成支付后再发货。";
          }
          return tip;
      },
    clearRegetQrCodeTimeout() {
      clearTimeout(this.regetQrCodeTimer);
    },
    createRegetQrCodeTimeout() {
      this.clearRegetQrCodeTimeout();
      if (this.qrCodeModal.overtime > 0) {
        this.regetQrCodeTimer = setTimeout(() => {
          this.clearRegetQrCodeTimeout();
          this.qrCodeModal.overtime -= 1;
          this.createRegetQrCodeTimeout();
        }, 1000);
      } else {
        this.qrCodeModal.regetShow = true;
      }
    },
    // 跳转到账单列表页
    goToBillListPage() {
      this.$router.replace({
        name: "billList",
        params: {
          status: "0"
        }
      });
    },
    // 跳转到订单列表页
    goToOrderListPage() {
      this.$router.replace({
        name: "v2-order",
        params: {
          status: "0"
        }
      });
    },
    // 跳转到支付成功页
    goToPaymentSuccessPage() {
      this.$router.replace({
        name: "v2-payresult",
        params: {
          payType: "SUCCESS",
          orderId: this.id
        }
      });
    },
    // 关闭支付弹框
    closePayModal() {
      this.stopCheckOrderPayStatus();
      this.clearRegetQrCodeTimeout();
      this.qrCodeModal.show = false;
    },
    // 停止检查订单支付状态
    stopCheckOrderPayStatus() {
      clearInterval(this.order.payStatusInterval);
      this.order.payStatusInterval = null
    },
    // 开始检查订单支付状态
    startCheckOrderPayStatus() {
      this.order.payStatusInterval = setInterval(async () => {
        const result = await this.pullPaymentStatus();
        if (result.data.code == "200") {
          // const payStatus = result.data.data;
          // if (payStatus) {
            this.stopCheckOrderPayStatus();
            this.clearRegetQrCodeTimeout();
            this.goToPaymentSuccessPage();
          // }
        } else if (result.data.code == "309") {

        } else if (result.data.code == "310") {
          this.stopCheckOrderPayStatus();
          this.clearRegetQrCodeTimeout();
          this.$confirm('银行到账信息略有延迟，超5分钟支付状态未变更，请联系 业务员：' + this.payment.list.name + "，电话：" + this.payment.list.phone, '银行到账信息确认中……', {
              confirmButtonText: '确定',
              showCancelButton: false, //是否显示取消按钮
              showClose: false, //是否显示关闭按钮
              closeOnClickModal: false, //是否可以通过点击空白处关闭弹窗
              type:'info'
          }).then(() => {
              // 成功
              this.goToOrderListPage();
          }).catch(() => {
              // 失败
              this.goToOrderListPage();
          })
        } else {
          this.stopCheckOrderPayStatus();
          this.clearRegetQrCodeTimeout();
        }
      }, 2000);
    },
    // 停止检查账单支付状态
    stopCheckBillPayStatus() {
      clearInterval(this.billInfo.payStatusInterval);
    },
    // 开始检查账单支付状态
    startCheckBillPayStatus() {
      this.billInfo.payStatusInterval = setInterval(async () => {
        const result = await this.pullPaymentStatus();
        if (result.data.code === "200") {
          const payStatus = result.data.data;
          if (payStatus) {
            this.stopCheckBillPayStatus();
            this.clearRegetQrCodeTimeout();
            this.goToPaymentSuccessPage();
          }
        }
      }, 2000);
    },
    // 拉取订单/账单支付状态
    pullPaymentStatus() {
      return this.$getHttpClient({
        useSpinner: false,
        useResponseToast:false
      }).get("/pjyy-deepexi-pay-center/pay/v2/PublicPay/queryOrder?orderNumber="+this.id + "&orderType=1");
    },
    // 倒计时停止跳转到订单列表
    countDownStopHandler() {
      this.stopCheckOrderPayStatus();
      this.clearRegetQrCodeTimeout();
      this.goToOrderListPage();
    },
    // 拉取订单详情
    pullOrderDetailData() {
      return this.$getHttpClient()
        .get("/pjyy-deepexi-order-center/api/v1/order/OcOrderLine/orderLineList",
          {
            params: {
              orderNumber: this.id
            }
          }
        )
        .then(responses => {
          const data = responses.data;
          if (data.code === "200") {
            return data.data;
          }
        });
    },
    // 初始化订单详情信息
    async initOrderDetailData() {
      const result = await this.pullOrderDetailData();
      if (result) {
        this.order.data = result;
        this.countDown.value = Math.floor(result.closeTime / 1000) + 1;
        this.countDown.show = true;
      }
    },
    // 拉取账单详情
    pullBillDetailData() {
      return this.$getHttpClient()
        .post("/pjyy-deepexi-order-center/api/v1/bill/viewBill", {
          documentNo: this.id
        })
        .then(responses => {
          const data = responses.data;
          if (data.code === "200") {
            return data.data;
          }
        });
    },
    // 初始化账单详情信息
    async initBillDetailData() {
      const result = await this.pullBillDetailData();
      if (result) {
        this.billInfo.data = result;
      }
    },
    // 拉取订单支付二维码信息
    pullOrderQrCodeData(useSpinner = true) {
      const data = {
        orderSource: 5,
        orderOrErpId: this.id,
        orderType: 2,
        environment: 2,
        titleName: "普健医药商城",
        companyId: this.userInfo.loginCompanyId,
        payChannel: this.payment.mode,
        payMode: this.payment.mode,
        payType: this.payment.mode == "ALIPAY" ? 2 : this.payment.mode == "WXPAY" ? 1 : 0,
        tagId:accountStorage.getCacheUserInfo().account,
        payCode:"CTOB",
        transTerminalType:1,//pc端
        transChannelType:1//pc端
      };
      return this.$getHttpClient({
        useSpinner,
        useResponseToast: false
      })
        .post("/pjyy-deepexi-pay-center/pay/v2/PublicPay/payOrder", data)
        .then(responses => {
          const data = responses.data;
          if (data.code == "200") {
            return data.data;
          } else {
            this.$toast.mount(
              this.$toast.create(data.msg, {
                unmounted: () => {
                  this.goToOrderListPage();
                }
              })
            );
          }
        });
    },
    // 拉取账单支付二维码信息
    pullBillQrCodeData(useSpinner = true) {
      const loginCompanyId = accountStorage.getCacheUserInfo().loginCompanyId;
      const data = {
        companyId: loginCompanyId,
        documentNo: this.id,
        payChannel: this.payment.mode,
        payType: "NATIVE",
        titleName: "普健医药商城"
      };
      return this.$getHttpClient({
        useSpinner,
        useResponseToast: false
      })
        .post("/pjyy-deepexi-pay-center/api/v1/bill/pay", data)
        .then(responses => {
          const data = responses.data;
          if (data.code === "200") {
            return data.data;
          } else {
            this.$toast.mount(
              this.$toast.create(data.msg, {
                unmounted: () => {
                  this.goToBillListPage();
                }
              })
            );
          }
        });
    },
    //创建农商行表单提交
    createForm(res) {
      const responseData = {
          orig: res.orig,
          sign: res.sign,
          returnurl: res.returnurl,
          informurl: res.informurl,
          transName: res.transName,
          repeatInform: res.repeatInform,
      }
      // 根据返回的数据创建隐藏的表单元素
      const form = document.createElement('form');
      form.action = res.url; // 替换为实际的action值
      form.method = 'post';
      if (form && document.body) {
        for (const key in responseData) {
            if (responseData.hasOwnProperty(key)) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = responseData[key]; // 使用接口返回的值设置input的值
                form.appendChild(input);
            }
        }

        // 添加提交按钮
        const submitBtn = document.createElement('input');
        submitBtn.type = 'submit';
        submitBtn.name = 'submitBtn';
        submitBtn.value = '正在跳转重庆农村商业银行，请稍等...'; // 替换为实际的提交按钮值
        form.appendChild(submitBtn);
        // 将表单添加到DOM中
        document.body.appendChild(form);
        // 模拟表单提交
        const HTMLFormElementSubmit = (form => () => {
            try {
                const newWindow = window.open('', '_blank'); // 打开新的空白标签页
                newWindow.document.write(form.outerHTML); // 在新窗口中写入表单HTML
                if ('submit' in form) {
                    newWindow.document.forms[0].submit(); // 提交表单
                    // 提交完毕后删除表单
                    form.parentNode.removeChild(form);
                } else {
                    newWindow.document.forms[0].dispatchEvent(new Event('submit', { bubbles: true }));
                }
            } catch (error) {
                console.error(error);
            }
        })(form);

        HTMLFormElementSubmit();
      } else {
          console.error('form or document.body is null');
      }
    },
      // 初始化支付二维码信息
    async initQrCodeInfo(useSpinner = true) {
      let result = {};
      // if (this.type === "bill") {
      //   result = await this.pullBillQrCodeData(useSpinner);
      // } else {
        result = await this.pullOrderQrCodeData(useSpinner);
      // }

      if (result) {
        // 农商行
        if (result.payChannel == "CQNCSYPAY") {
          this.createForm(result.object)
          this.startCheckOrderPayStatus();
        } else {
          this.startCheckOrderPayStatus();

          // 支付宝(ALIPAY)、微信支付(WXPAY)  微信二维码私人账户(WXPRIVATEPAY)  支付宝二维码私人账户(ALIPRIVATEPAY)
          if (this.payment.mode !== "ALIPAY" && this.payment.mode !== "WXPAY" && this.payment.mode !== "WXPRIVATEPAY" && this.payment.mode !== "ALIPRIVATEPAY") {
            window.open(result.object.url)
          } else {
            this.qrCodeModal.qrCodeUrl = result.object.url;
            this.qrCodeModal.regetShow = false;
            this.qrCodeModal.overtime = 60;
            this.qrCodeModal.show = true;
            this.qrCodePaymentMethod = this.payment.mode == 'WXPAY' || this.payment.mode == 'WXPRIVATEPAY' ? '微信' : '支付宝';
            // this.createRegetQrCodeTimeout();
          }
        }
      }
      return result;
    },
    // 拉取支付方式
    pullPayMode() {
      let params = {
        orderType: this.type,
        orderNumber: this.id,
        code: this.userInfo.account,
        staffCode: this.userInfo.staffCode,
        channelType: 3
      }
      return this.$getHttpClient().post("/pjyy-deepexi-pay-center/pay/v2/PublicPay/getpayInfoPage", params).then(res => {
        const data = res.data;
        if (data.code == 200) {
          return data.data;
        }
      });
    },
    // 初始化支付方式
    async initPayMode() {
      const result = await this.pullPayMode();
      if (result) {
        // this.payment.mode = result.immediatePayment.defaultPay;
        if (result.immediatePayment.show && result.offlinePayment.show){
            if(result.offlinePayment.payName == "COD" || result.piaozhe3){
                result.immediatePayment.checked = true
                result.offlinePayment.checked = false
                this.actIndex = 0;
            }else if (result.offlinePayment.payName == "OFFLINEPAY"){//如果是线下付款 默认选中
                result.immediatePayment.checked = false
                result.offlinePayment.checked = true
                this.actIndex = 1;
            }
        }else if (result.immediatePayment.show && !result.offlinePayment.show){
            result.immediatePayment.checked = true
            this.actIndex = 0;
        }else if (!result.immediatePayment.show && result.offlinePayment.show){
            result.offlinePayment.checked = true
            this.actIndex = 1;
        }
        this.payment.list = result;
      }
    },
    // 刷新二维码
    async regetQrCode() {
      await this.initQrCodeInfo(false);
    },
    // 去支付
    toPayModal: debounce(async function (e) {
      if (this.actIndex === 0 && !this.payment.mode){
          this.$messageTip("请选择支付方式！",'error');
        return
      }
      if (this.order.payStatusInterval){
          this.$messageTip("当前正在支付中，请等待支付完成！",'error');
          return
      }
      let params = {
        orderNumber: this.id,
        ...this.paymentData,
        payType: e,
      }
        if (e == "OFFLINE"){
            params.name = this.payment.list.offlinePayment.payName
        }
      let url = "/pjyy-deepexi-order-center/rpc/v3/order/OcOrder/updateOrder";
      const result = await this.$getHttpClient().post(url, params);
      const data = result.data;
      if (data.code == '200') {
        if (this.type == "order"){
            if (this.actIndex === 0){
                await this.initQrCodeInfo();
            }else{
                if (typeof data.payload != 'undefined'){
                    if (data.payload.statusCode === 5) {
                        this.arrearsData = data.payload;
                        this.showDebt = true;
                    }
                }else{
                    this.goToPaymentSuccessPage()
                }
            }
        }else if (this.type == "bill"){
            await this.initQrCodeInfo();
        }
      }
    },500),
  }
};
</script>

<style src="./pay-ment.less" lang="less"></style>
